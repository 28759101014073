/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';

import PropTypes from 'prop-types';

import './layout.css';

export const Layout = ({ children }) => (
  <>
    <div
      style={{
        margin: `auto`, // auto, damit der Inhalt horizontal zentriert steht (vorher: 0)
        maxWidth: 1920,
        // padding: `0 1.0875rem 1.45rem`,
      }}
    >
      <main>{children}</main>
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
